<template>
	<div>
		<!-- BEGIN: HTML Table Data -->
		<div class="intro-y box p-5 mt-5">
			<div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
				<form
					@submit.prevent="onFilter"
					id="tabulator-html-filter-form"
					class="xl:flex sm:mr-auto"
				>
					<div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
						<input
							id="tabulator-html-filter-value"
							v-model="filter.value"
							type="text"
							class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
							placeholder="Search..."
							@keyup="onFilter"
						/>
					</div>
					<div class="mt-2 xl:mt-0">
						<button
							id="tabulator-html-filter-reset"
							type="button"
							class="btn  w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
							@click="onResetFilter"
						>
							Reset
						</button>
					</div>
				</form>
				<div class="mr-4">
					<button
						v-if="showCreateSG"
						type="button"
						class="btn w-full nymus-orange color-white"
						@click="onCreateSG"
					>
						Create SG
					</button>
				</div>
				<div class="flex mt-5 sm:mt-0"></div>
			</div>
			<div class="overflow-x-auto scrollbar-hidden">
				<div
					id="tabulator"
					ref="tableRef"
					class="mt-5 table-report table-report--tabulator"
				></div>
			</div>
		</div>
		<!-- END: HTML Table Data -->
	</div>
	<CreateSupplierGroup @trigger="onFilter"/>
</template>

<script>
import {computed, defineComponent, onBeforeMount, onMounted, reactive, ref, watch} from "vue"
import feather from "feather-icons"
import {TabulatorFull as Tabulator} from 'tabulator-tables'
import cash from "cash-dom"
import {useRouter} from "vue-router"
import {useStore} from "vuex"
import routerData from "../routerData"
import CreateSupplierGroup from "../components/AddSupplierGroup/AddSupplierGroup.vue"

const env = window.environment

export default defineComponent({
	name: "TabulatorSG",
	props: {
		token: String,
		requestUrl: String,
	},
	components: {
		CreateSupplierGroup
	},
	setup(props) {
		const requestUrl = ref(props.requestUrl);
		const token = ref(props.token);
		const store = useStore();
		const router = useRouter();
		let showCreateSG = computed(() => {
			return !(store.state.currentUser.group === 'NymusViewer' );
		})
		let ingestionClicked = ref(false)

		watch(
			() => props.token,
			(newVal) => {
				token.value = newVal
			}
		)
		watch(() => store.state.schedule.jobs, (newVal) => {
			if (ingestionClicked.value || store.state.overviewTableDataUpdated) {
				store.commit("overviewTableDataUpdated", false)
				ingestionClicked.value = false
			}
		})
		watch(() => store.state.ingestionStatus, (newVal) => {
			if (newVal.sg && newVal.type) {
				let clickedElement = document.getElementById(newVal.sg)
				if (newVal.type === "start") {
					clickedElement.classList.remove("ingestion-false")
					clickedElement.classList.add("ingestion-true")
					clickedElement.innerHTML = "Running"
				} else {
					clickedElement.classList.remove("ingestion-true")
					clickedElement.classList.add("ingestion-false")
					clickedElement.innerHTML = "Not Running"
				}
			}
		})
		watch(() => store.state.printStatus, (newVal) => {
			if (newVal.sg && newVal.type) {
				let clickedElement = document.getElementById('printSchedule-' + newVal.sg)
				if (newVal.type === "start") {
					clickedElement.classList.remove("ingestion-false")
					clickedElement.classList.add("ingestion-true")
					clickedElement.innerHTML = "Running"
				} else {
					clickedElement.classList.remove("ingestion-true")
					clickedElement.classList.add("ingestion-false")
					clickedElement.innerHTML = "Not Running"
				}
			}
		})
		watch(() => store.state.pauseApiIngestionChanged, (newVal) => {
			let element = document.getElementById("pauseApiIngestion-"+ newVal.sg)
			if (newVal.value){
				element.classList.remove("ingestion-true")
				element.classList.add("ingestion-false")
				element.innerHTML = "Paused"
			} else {
				element.classList.remove("ingestion-false")
				element.classList.add("ingestion-true")
				element.innerHTML = "Running"
			}
		})

		const tableRef = ref()
		const tabulator = ref()
		const filter = reactive({
			field: "name",
			type: "like",
			value: store.state.filterSG,
		})
		const initTabulator = () => {
			tabulator.value = new Tabulator(tableRef.value, {
				ajaxURL: requestUrl.value,
				ajaxConfig: {
					method: "GET", //set request type to Position
					headers: {
						"Authorization": "bearer " + token.value,
						"Content-type": "application/json; charset=utf-8",
					},
				},
				ajaxResponse: async function (url, params, response) {
					let data = [];
					const res = response.data.supplierGroups;
					const schedules = store.state.schedule.jobs;

					for (let item of res) {
						let found = store.state.schedule.jobs.find((i) => i.supplierGroupId === item.supplierGroupId  && i.jobType === "PRINT")
						data.push({
							logo: item.channelsConfig.docImage.configPortal.logo,
							supplierGroupId: item.supplierGroupId,
							name: item.name,
							ingestion: {bool: undefined, text: "Not Running"},
							portalLink: item.channelsConfig.docImage.configPortal.portalLink,
							showPauseAPI: !!item.apiConfig?.apiKey,
							pauseApiIngestion: !!item.apiConfig?.pauseIngestion,
							showPrintSchedule: item.channelsConfig.print?.printProvider === "EASYPOST",
							printSchedule: found ? found.started : false
						})
					}

					for (let item of schedules) {
						const found = data.find(
							(element) => element.supplierGroupId === item.supplierGroupId
						)
						const text = item.started ? "Running" : "Not Running";

						if (item.jobType === "INGESTION") {
							found.ingestion = {bool: item.started, text: text};
						} else if (item.jobType === "PRINT") {
							found.print = {bool: item.started, text: text};
						}
					}
					return data //return the tableData property of a response json object
				},
				ajaxContentType: "json",
				ajaxFiltering: false,
				printAsHtml: true,
				printStyled: true,
				pagination:"local",
				paginationSize: 10,
				layout: "fitColumns",
				reactiveData: true,
				responsiveLayout: "collapse",
				placeholder: "No matching records found",
				columns: [
					{
						title: "",
						field: "logo",
						width: "150",
						hozAlign: "right",
						formatter(cell) {
							return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-start">
                        				<img class="object-contain h-6 w-auto" alt="No logo"  class="object-cover" src="${cell.getData().logo}"/>
                    				</div>`
						}
					},
					{
						title: "NAME",
						field: "name",
						hozAlign: "center",
						formatter(cell) {
							return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                          				<div>${cell.getData().name}</div>
                    				</div>`
						}
					},
					{
						title: "QUICK STARTS",
						field: "",
						hozAlign: "center",
						formatter(cell) {
							const a =
								cash(`<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                            				  	<button
                            						id="quickActionsIngestion"
                            						ref="${cell.getData().supplierGroupId}"
                            						class="quickActions-btn btn btn-sm w-24 mr-1 mb-2"
                            					>
                            						INGESTION
                            					</button>
                    						  </div>`)
							cash(a).on("click", async function (event) {
								event.target.disabled = true
								event.target.classList.add("isClicked")
								let ref = event.target.attributes.ref.value;
								//QUICK ACTION INGESTION
								if (event.target.id === "quickActionsIngestion") {
									await store.dispatch("schedule/startQuickActionIngestion", ref);
									if (store.state.responseStatus === 200) {
										store.commit("toggleAlert", {
											title: cell.getData().name,
											text: "Started QA Ingestion ",
										})
									} else {
										store.commit("toggleAlert", {
											title: cell.getData().name,
											text: "ERROR",
										})
									}
								}

								if (store.state.responseStatus === 200) {
									setTimeout(() => {
										event.target.disabled = false
										event.target.classList.remove("isClicked")
									}, 2000)
								}
							})
							return a[0]
						},
						cellClick: function (e) {
							store.state.tabulator.isClickBlocked = true
						}
					},
					{
						title: "INGESTION SCHEDULE",
						field: "",
						hozAlign: "center",
						formatter(cell) {
							const a =
								cash(`<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                            					<button
                            						id = "${cell.getData().supplierGroupId}"
                            						ref="${cell.getData().supplierGroupId}"
                            						class="ingestion-${cell.getData().ingestion.bool} btn btn-sm w-24 mr-1 mb-2"
                            					>
													${cell.getData().ingestion.text}
												</button>
                          					  </div>`
								)
							cash(a).on("click", async function (event) {
								store.commit("setSgTableCurrentPage", tabulator.value.getPage())
								ingestionClicked.value = true
								let ref = event.target.attributes.ref.value
								let schedules = store.state.schedule.jobs
								let found = schedules.find((item) => item.supplierGroupId === ref && item.jobType === "INGESTION")
								if (found) {
									if (!found.started) {
										await store.dispatch("schedule/startIngestionSchedule", ref)
										if (store.state.responseStatus === 200) {
											store.commit("toggleAlert", {
												title: cell.getData().name,
												text: "Started Ingestion Schedule",
											})
										} else {
											store.commit("toggleAlert", {
												title: cell.getData().name,
												text: "ERROR",
											})
										}
									} else if (found.started) {
										await store.dispatch("schedule/stopIngestionSchedule", ref)
										if (store.state.responseStatus === 200) {
											store.commit("toggleAlert", {
												title: cell.getData().name,
												text: "Stopped Ingestion Schedule",
											})
										} else {
											store.commit("toggleAlert", {
												title: cell.getData().name,
												text: "ERROR",
											})
										}
									}
								} else {
									//Never started || Undefined
									await store.dispatch("schedule/startIngestionSchedule", ref);
									if (store.state.responseStatus === 200) {
										store.commit("toggleAlert", {
											title: cell.getData().name,
											text: "Started Ingestion Schedule",
										})
									} else {
										store.commit("toggleAlert", {
											title: cell.getData().name,
											text: "ERROR",
										})
									}
								}
							})
							return a[0]
						},
						cellClick: function (e) {
							store.state.tabulator.isClickBlocked = true
						}
					},
					{
						title: "Pause ingestion API",
						field: "",
						hozAlign: "center",
						formatter(cell) {
							const a =
								cash(`<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center  pause-api-${cell.getData().showPauseAPI}">
                            				  	<button
                            						id="pauseApiIngestion-${cell.getData().supplierGroupId}"
                            						class="ingestion-${!cell.getData().pauseApiIngestion} btn btn-sm w-24 mr-1 mb-2"
                            					>
                            						${cell.getData().pauseApiIngestion ? 'Paused' : 'Running'}
                            					</button>
                    						  </div>`)
							cash(a).on("click", async function () {
								await store.dispatch("schedule/pauseApiIngestion", {supplierGroupId: cell.getData().supplierGroupId, pauseIngestion: document.getElementById('pauseApiIngestion-' + cell.getData().supplierGroupId).innerHTML.trim() === 'Running'});
							})
							return a[0]
						},
						cellClick: function (e) {
							store.state.tabulator.isClickBlocked = true
						}
					},
					{
						title: "Print schedule",
						field: "",
						hozAlign: "center",
						formatter(cell) {
							const a =
								cash(`<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center  pause-api-${cell.getData().showPrintSchedule}">
                            				  	<button
                            						id="printSchedule-${cell.getData().supplierGroupId}"
                            						class="ingestion-${cell.getData().printSchedule} btn btn-sm w-24 mr-1 mb-2"
                            					>
                            						${cell.getData().printSchedule ? 'Running': 'Not Running'}
                            					</button>
                    						  </div>`)
							cash(a).on("click", async function () {
								let running =  document.getElementById('printSchedule-' + cell.getData().supplierGroupId).innerHTML.trim() === 'Running'
								const payload = {supplierGroupId: cell.getData().supplierGroupId, printSchedule: running}
								let resp = await store.dispatch("schedule/printSchedule", payload);
								await store.dispatch('schedule/fetchSchedule', null)

								if (resp.status === 200){
									store.commit("setPrintStatus" , {type : running ? "stop": "start", sg: payload.supplierGroupId}, {root: true})

									if (running){
										store.commit("toggleAlert", {
											title: cell.getData().name,
											text: "Stopped print schedule ",
										})
									} else {
										store.commit("toggleAlert", {
											title: cell.getData().name,
											text: "Started print schedule",
										})
									}
								}
							})
							return a[0]
						},
						cellClick: function (e) {
							store.state.tabulator.isClickBlocked = true
						}
					},
					{
						title: "PORTAL",
						field: "",
						hozAlign: "center",
						headerSort: false,
						formatter(cell) {
							const a =
								cash(`<div>
                       							<button class="quickActions-btn btn btn-sm w-24 mr-1 mb-2">GO TO PORTAL</button>
 											  </div>`
								)
							cash(a).on('click', () => {
								window.open(cell.getData().portalLink, '_blank');
							})
							return a[0]
						},
						cellClick: function (e) {
							store.state.tabulator.isClickBlocked = true
						}
					}
				]
			})
			tabulator.value.on('rowClick', async (_, row) => {
				await store.dispatch("updateToken")
				setTimeout(async () => {
				if(store.state.tabulator.isClickBlocked === false){
					store.commit('setOverviewTablePage', tabulator.value.getPage());
					await router.push(routerData.SGOverview.link + "/" + row._row.data.supplierGroupId);

				} else store.state.tabulator.isClickBlocked = false
				}, 300)
			})
			onFilter()
		}

		const tokenIsExpired = () => {
			let exp
			if (JSON.parse(atob(store.state.token.split('.')[1])).exp) {
				exp = parseInt(JSON.parse(atob(store.state.token.split('.')[1])).exp)
			}
			let now = parseInt(new Date().getTime() / 1000)
			return exp - now <= 0;
		}

		// Redraw table onresize
		const reInitOnResizeWindow = () => {
			window.addEventListener("resize", () => {
				tabulator.value.redraw();
				feather.replace({
					"stroke-width": 1.5,
				});
			});
		};
		// Filter function
		const onFilter = () => {
			store.state.filterSG = filter.value
			tabulator.value.setFilter([
				[
					{field: "name", type: filter.type, value: filter.value},
					{field: "supplierGroupId", type: filter.type, value: filter.value},
				],
			])
		}

		// On reset filter
		const onResetFilter = () => {
			filter.field = "name"
			filter.type = "like"
			filter.value = ""
			onFilter()
		};

		const onCreateSG = () => {
			store.state.displayAddSupplierGroup = true
		}

		// Export
		const onExportCsv = () => {
			tabulator.value.download("csv", "data.csv")
		}

		const onExportJson = () => {
			tabulator.value.download("json", "data.json")
		}

		const onExportHtml = () => {
			tabulator.value.download("html", "data.html", {
				style: true,
			})
		}

		// Print
		const onPrint = () => {
			tabulator.value.print()
		}
		const test = () => {
			tabulator.value.setPage(2)
		}
		onBeforeMount(async () => {
			await store.dispatch('updateToken')
		})

		onMounted(() => {
			initTabulator();
			reInitOnResizeWindow();

			setTimeout(() => {
				tabulator.value.setPage(store.state.sgOverviewPageNumber)
			}, 250)
		})

		return {
			tableRef,
			filter,
			onFilter,
			onResetFilter,
			onExportCsv,
			onCreateSG,
			onExportJson,
			onExportHtml,
			onPrint,
			showCreateSG,
			test
		}
	}
})
</script>

<style>
.tabulator-arrow {
	display: none;
}

.tabulator-col-title {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	justify-items: center;
}

/*Printing Classes*/
.print-undefined,
.print-SPEOS {
	display: none !important;
}

.ingestion-false,
.ingestion-undefined {
	background-color: #d32929;
	color: white;
}

.ingestion-true {
	background-color: #91c714;
	color: white;
}
.pause-api-true {
	display: block;
}
.pause-api-false {
	display: none !important;
}

.print-false,
.print-undefined {
	background-color: #d32929;
	color: white;
}

.print-true {
	background-color: #91c714;
	color: white;
}

.quickActions-btn {
	background-color: #00adea;
	color: white;
}

.isClicked {
	background-color: lightgrey;
}
</style>