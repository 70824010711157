<template>
	<div class="w-full">
		<div class="content">
			<div class="intro-y flex flex-col col-span-12 mt-5 box md:col-span-6 lg:col-span-4">
				<div class="flex flex-row w-full">
					<div
						class="w-full text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5 min-height-600">
						<p class="font-bold form-label mb-2">Mass resend Postmark:</p>
						<div class="w-1/2" style="margin-top: 5px">
                            <textarea
								placeholder=" supplierGroupId;docId;email;language;channelType"
								rows="8"
								v-model.trim="data"
								type="text"
								class="textarea-style"
							/>
							<button @click="showPopupStructure"
									style="background-color: #291f8d; color: white; border-radius: 50%; padding-top: 5px; padding-right: 5px; padding-left: 5px; position: absolute; margin-left: -30px; margin-top: 5px">
								<span class="material-icons" style="font-size: 16px">help_outline</span>
							</button>
							<new-alert
								title="Structure mass resend"
								:is-visible="showStructureResend"
								text="Structure: supplierGroupId;docId;email;language;channelType
								Examples:
								- axa;axa-1234;test@nymus.be;NL;AttachEmail
								- axa;axa-4321;test@nymus.be;EN;LinkEmail"
								position-higher="50"
							></new-alert>
						</div>
						<div v-if="stringsWithErrors.length > 0">
							<div v-for="error in stringsWithErrors" :key="error">
								<p class="error">{{ error }}</p>
							</div>
						</div>

						<div class="mt-2 w-1/2 button-container">
							<button @click="send" class="btn-bl btn btn-sm mr-1 mb-2 text-white bg-theme-1">
								Resend
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import languages from "@/langCodes";
import NewAlert from "@/components/NewAlert.vue";

export default {
	name: "PeppolPage",
	components: {NewAlert},
	async mounted() {
		await this.$store.dispatch("updateToken")
	},
	data() {
		return {
			supplierGroups: this.$store.state.supplierGroups,
			data: "",
			stringsWithErrors: [],
			errors: false,
			showStructureResend: false,
		}
	},
	methods: {
		showPopupStructure() {
			this.showStructureResend = true
			setTimeout(() => {
				this.showStructureResend = false
			}, 20000)
		},
		validateInput(data) {
			const dataLine = data.split(";")

			let sgOk
			let emailOk
			let channelTypeOk
			let emailRegex = this.$store.emailRegex
			let lengthArrayOk
			let languageOk

			lengthArrayOk = dataLine.length === 5;
			if (lengthArrayOk === false) {
				let errorMessage = "There is a validation error in next line: '" + data + "'.\n"
				if (!lengthArrayOk) errorMessage = errorMessage + "- Please give 4 parameters: SupplierGroupId;docId;email;channelType\n"
				return false
			}
			if (lengthArrayOk) {
				sgOk = false
				languageOk = false
				this.supplierGroups.forEach(sg => {
					if (sg.supplierGroupId === dataLine[0]) sgOk = true
				})
				emailOk = !!dataLine[2].match(emailRegex);

				languages.forEach(lang => {
					if (lang.name === dataLine[3].toUpperCase()) languageOk = true
				})
				channelTypeOk = dataLine[4] === "AttachEmail" || dataLine[4] === "LinkEmail";
			}

			if (sgOk === false || emailOk === false || channelTypeOk === false || languageOk === false) {
				let errorMessage = "There is a validation error in next line: '" + data + "'.\n"
				if (!sgOk) errorMessage = errorMessage + "- Couldn't find the supplier group\n"
				if (!emailOk) errorMessage = errorMessage + "- Invalid email address\n"
				if (!languageOk) errorMessage = errorMessage + "- Invalid language\n"
				if (!channelTypeOk) errorMessage = errorMessage + "- ChannelType has to be exactly: AttachEmail or LinkEmail\n"
				this.stringsWithErrors.push(errorMessage)
				return false
			}
			return true
		},
		send() {
			this.stringsWithErrors = []
			let massResend = []
			let stringsWithError = []
			const dividedString = this.data.split("\n");
			dividedString.forEach((string) => {
				const validString = this.validateInput(string)
				if (validString) {
					const variable = string.split(";")
					massResend.push({
						supplierGroupId: variable[0],
						docId: variable[1],
						email: variable[2],
						language: variable[3].toUpperCase(),
						channelType: variable[4]
					})
				} else {
					stringsWithError.push(string)
				}
			})

			const payload = {
				emailInfo: massResend
			}

			this.$store.dispatch("globalActions/sendMassResendPostmark", payload)
			this.data = ""
			stringsWithError.forEach(string => {
				this.data = this.data + string + "\n"
			})
			this.data.trim()
		}
	}
}
</script>

<style scoped>
.textarea-style {
	border: 1px solid rgb(226, 232, 240);
	border-radius: 4px;
	width: 100%;
}

.textarea-style:focus, .textarea-style:focus-visible {
	box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgba(59, 130, 246, 0.5) 0px 0px 0px 3px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px !important;
	border: 2px solid rgb(226, 232, 240) !important;
	outline-style: hidden !important;
}

.button-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	justify-items: end;
}
</style>
